<template>
    <div>
        <div class="SimilarProject">
            <div class="SimilarProject-content">
                <div class="SimilarProject-content-top">
                    <h2>类似项目</h2>
                </div>
                <div class="tree">
                    <el-tree
                        :data="data"
                        :props="defaultProps"
                        @node-click="handleNodeClick"
                    ></el-tree>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SimilarProject",
    data() {
        return {
            data: [
                {
                    //
                    label: "头部3d雕塑(10~30美元)",
                    children: [
                        {
                            label: "二级 1-1",
                            children: [
                                {
                                    label: "三级 1-1-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "Shorty the Klown Bust 3d模型（30-250美元）",
                    children: [
                        {
                            label: "二级 2-1",
                            children: [
                                {
                                    label: "三级 2-1-1",
                                },
                            ],
                        },
                        {
                            label: "二级 2-2",
                            children: [
                                {
                                    label: "三级 2-2-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "一个元素很少的建模（30-250加元）",
                    children: [
                        {
                            label: "二级 3-1",
                            children: [
                                {
                                    label: "三级 3-1-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "室内设计师（30-250美元）",
                    children: [
                        {
                            label: "二级 3-1",
                            children: [
                                {
                                    label: "三级 3-1-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "我需要能够创建高质量 3D 虚拟世界的设计师（5000-1000美元）",
                    children: [
                        {
                            label: "四级 4-1-1",
                        },
                    ],
                },
                {
                    label: "完成Revit模型（₹600-1500 INR）",
                    children: [
                        {
                            label: "五级 4-1-1",
                        },
                    ],
                },
                {
                    //
                    label: "头部3d雕塑(10~30美元)",
                    children: [
                        {
                            label: "二级 1-1",
                            children: [
                                {
                                    label: "三级 1-1-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "Shorty the Klown Bust 3d模型（30-250美元）",
                    children: [
                        {
                            label: "二级 2-1",
                            children: [
                                {
                                    label: "三级 2-1-1",
                                },
                            ],
                        },
                        {
                            label: "二级 2-2",
                            children: [
                                {
                                    label: "三级 2-2-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "一个元素很少的建模（30-250加元）",
                    children: [
                        {
                            label: "二级 3-1",
                            children: [
                                {
                                    label: "三级 3-1-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "室内设计师（30-250美元）",
                    children: [
                        {
                            label: "二级 3-1",
                            children: [
                                {
                                    label: "三级 3-1-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "我需要能够创建高质量 3D 虚拟世界的设计师（5000-1000美元）",
                    children: [
                        {
                            label: "四级 4-1-1",
                        },
                    ],
                },
                {
                    label: "完成Revit模型（₹600-1500 INR）",
                    children: [
                        {
                            label: "五级 4-1-1",
                        },
                    ],
                },
            ],
            defaultProps: {
                children: "children",
                label: "label",
            },
        };
    },

    methods: {
        handleNodeClick(data) {
            console.log(data);
        },
    },
};
</script>

<style lang="less" scoped>
.SimilarProject {
    margin-top: 20px;
    padding-bottom: 40px;
    overflow: hidden;
    width: 280px;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px #e1e1e1;
    .SimilarProject-content {
        width: 240px;
        margin: 0 auto;
        .SimilarProject-content-top {
            margin-top: 4px;
            line-height: 46px;
            border-bottom: 1px solid #b5b5b5;
        }
    }
}
// el-tree
/deep/.el-tree {
    margin-top: 15px;
}
/deep/.el-tree-node__label {
    width: 196px;
    font-size: 12px;
}
.tree {
    /deep/.el-tree-node {
        white-space: normal;
        /deep/ .is-expanded {
            margin-bottom: 0;
        }
    }
}

/deep/.el-tree-node__content {
    width: 240px;
    height: 35px;
    align-items: start;
}
</style>
