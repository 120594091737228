<template>
    <div>
        <div class="info-border-right-employer">
            <div class="info-border-right-employer-center">
                <div class="info-border-right-employer-center-top">
                    <h2>雇主其他信息</h2>
                </div>
                <el-tree
                    :data="data"
                    :props="defaultProps"
                    @node-click="handleNodeClick"
                ></el-tree>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Employer",
    data() {
        return {
            data: [
                {
                    //
                    label: "头部3d雕塑(10~30美元)",
                    children: [
                        {
                            label: "二级 1-1",
                            children: [
                                {
                                    label: "三级 1-1-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "Shorty the Klown Bust 3d模型(30-250美元)",
                    children: [
                        {
                            label: "二级 2-1",
                            children: [
                                {
                                    label: "三级 2-1-1",
                                },
                            ],
                        },
                        {
                            label: "二级 2-2",
                            children: [
                                {
                                    label: "三级 2-2-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "一个元素很少的建模(30-250加元)",
                    children: [
                        {
                            label: "二级 3-1",
                            children: [
                                {
                                    label: "三级 3-1-1",
                                },
                            ],
                        },
                    ],
                },
                {
                    label: "室内设计师（30-250美元）",
                    children: [
                        {
                            label: "二级 3-1",
                            children: [
                                {
                                    label: "三级 3-1-1",
                                },
                            ],
                        },
                    ],
                },
            ],
            defaultProps: {
                children: "children",
                label: "label",
            },
        };
    },
    methods: {
        handleNodeClick(data) {
            console.log(data);
        },
    },
};
</script>

<style lang="less" scoped>
.info-border-right-employer {
    margin-top: 20px;
    width: 280px;
    overflow: hidden;
    background: #ffffff;
    display: flex;
    padding-bottom: 40px;
    box-shadow: 0px 0px 10px 0px #e1e1e1;
    .info-border-right-employer-center {
        width: 240px;
        margin: 0 auto;
        .info-border-right-employer-center-top {
            margin-top: 4px;
            line-height: 46px;
            border-bottom: 1px solid #b5b5b5;
        }
    }
}
// el-tree
/deep/.el-tree {
    margin-top: 15px;
}
/deep/.el-tree-node__label {
    width: 196px;
    font-size: 12px;
    line-height: 15px;
}
/deep/.el-tree-node {
    white-space: normal;
    /deep/ .is-expanded {
        margin-bottom: 0;
    }
    /deep/.el-tree-node__content {
        width: 196px;
        height: 40px;
        align-items: start;
    }
}
</style>
