<template>
  <div class="person-att" :style="divStyle">
    <div class="person-att-header"><h2>认证信息</h2></div>
    <div class="person-att-bottom">
      <ul v-if="isPublic">
        <li>
          <Isvg icon-class="mobile-certification" />
          <span class="auth_name">手机号码认证</span>
          <a href="javascript:;" v-if="authInfo.mobile==9" class="h-57">已认证</a>
          <a href="javascript:;" v-else class="h-57">未认证</a>
        </li>

        <li>
          <Isvg icon-class="email-certification" />
          <span class="auth_name">电子邮箱认证</span>
          <a href="javascript:;" v-if="authInfo.email==9" class="h-57">已认证</a>
          <a href="javascript:;" v-else class="h-57">未认证</a>
        </li>

        <li>
          <Isvg icon-class="realname-certification" />
          <span class="auth_name">实名认证</span>
          <a href="javascript:;" v-if="authInfo.real_name==9" class="h-57">已认证</a>
          <a href="javascript:;" v-else class="h-57">未认证</a>
        </li>

        <li>
          <Isvg icon-class="enterprise-certification" />
          <span class="auth_name">企业认证</span>
          <a href="javascript:;" v-if="authInfo.company==9" class="h-57">已认证</a>
          <a href="javascript:;" v-else class="h-57">未认证</a>
        </li>
      </ul>

      <ul v-if="!isPublic">
        <!--        不对外公开的数据加去认证操作-->
        <li v-for="( authItem, authIndex ) in authData" :key="authIndex">
          <svg class="icon wh-16" aria-hidden="true">
            <use v-if="authIndex=='mobile'" xlink:href="#icon-phone" />
            <use v-if="authIndex=='email'" xlink:href="#icon-E-mail" />
            <use v-if="authIndex=='real_name'" xlink:href="#icon-a-PersonalIdentity" />
            <use v-if="authIndex=='company'" xlink:href="#icon-a-corporateidentity" />
          </svg>
          <span class="auth_name" v-if="authIndex=='mobile'">手机号码认证</span>
          <span class="auth_name" v-if="authIndex=='email'">电子邮箱认证</span>
          <span class="auth_name" v-if="authIndex=='real_name'">实名认证</span>
          <span class="auth_name" v-if="authIndex=='company'">企业认证</span>

          <a href="javascript:;" v-if="authItem.status==9" class="h-57">已认证</a>
          <a href="javascript:;" v-if="authItem.status==0" class="b" @click="toAuth( authIndex )">去认证</a>
          <a href="javascript:;" v-if="authItem.status==3&&authIndex=='real_name'" @click="toAuth( authIndex )" class="r">未通过</a>
          <a href="javascript:;" v-if="authItem.status==2&&authIndex=='real_name'" @click="toAuth( authIndex )" class="g">待审核</a>

          <el-popover
              v-if="authItem.status>0&&authIndex=='company'"
              placement="right-start"
              width="280"
              trigger="hover">
            <el-table
                style="padding-bottom: 20px"
                v-if="authIndex=='company'"
                :header-cell-style="{ 'background-color':'#eee' }"
                border
                :data="authItem.list"
                size="mini"
                :stripe="true"
                empty-text="您还没有认证!">
              <el-table-column width="150" property="company_name" label="认证名称"></el-table-column>
              <el-table-column width="80" property="status_name" label="认证状态" fixed="right"></el-table-column>
            </el-table>
            <el-link class="b" :underline="true" @click="toAuth( authIndex )" slot="reference">查看</el-link>
          </el-popover>

        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonAtt',
  props: {
    authInfo: {
      default: {}
    },
    isPublic: {
      default: false
    },
    divStyle: {}
  },
  data() {
    return {
      authData: {
        mobile: {},
        email: {},
        real_name: {},
        company: {}
      },
      companyData: []
    };
  },
  watch: {
    authInfo(newval) {
      if (newval) {
        this.authData = this.authInfo
      }
    }
  },
  methods: {
    toAuth(type) {
      switch ( type ){
        case 'email':
          this.$redirectUrl.jumpTo('/email')
          break;
        case 'mobile':
          this.$redirectUrl.jumpTo('/bind/mobile')
          break;
        case 'real_name':
          this.$redirectUrl.jumpTo('/authselect/1')
          break;
        case 'company':
          this.$redirectUrl.jumpTo('/authselect/2')
          break;
      }
    }
  }
};
</script>

<style lang="less">
.person-att {
  width: 200px;
  height: 170px;

  .person-att-header {
    margin-top: 20px;
    height: 37px;
    border-bottom: 1px solid #DCDDDD;

    h2 {
      color: #575757;
    }
  }

  .person-att-bottom {
    ul {
      margin-top: 20px;

      li {
        margin-bottom: 20px;
        font-size: 12px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        img {
          width: 16px;
          height: 16px;
        }

        .auth_name {
          display: inline-block;
          color: #575757;
          width: 125px;
          margin-left: 20px;
        }
      }
    }
  }

  .el-popover__reference-wrapper span {
    font-size: 12px !important;
  }
}

.el-link.el-link--primary {
  font-size: 12px !important;
}
</style>
