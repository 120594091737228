import regionJson from '../../public/js/region'; //地区地址json

export default (province_id, city_id, county_id) => {
    let region = regionJson.data;
    let address = '';
    region.forEach(province => {
        if (province.c == province_id) {
            address = province.n;
        }
        province.s.forEach(city => {
            if (city.c == city_id) {
                address += '-' + city.n;
            }
            city.s.forEach(country => {
                if (country.c == county_id) {
                    address += '-' + country.n;
                }
            });
        });
    });

    return address;
}
