<template>
  <el-container>
    <el-main>
      <div class="main-content" v-if="!isResult">
        <p class='realname_title' v-if="isAdd">
          <span class="r">*</span>&nbsp;&nbsp;&nbsp;身份证照片
        </p>
        <div class="realname_image_div" v-if="isAdd">
          <el-upload
              style="margin-right: 40px"
              accept="image/jpeg,image/png"
              :show-file-list="false"
              :on-success="realnamePic1UploadSuccess"
              :before-upload="beforeRealNameUpload"
              :data="{ side: 'face' }"
              :headers="headers"
              :action="postUrl">

            <div :class="{ 'audit_image': true, 'error': !uploadMsg1 }"
                 v-lazy-container="{ selector: 'img', error: require('@/assets/banner/idcard1.jpg'), loading: require('@/assets/banner/loading.png') }">
              <img v-if="realname_pic1==''" data-src="@/assets/banner/idcard1.jpg" alt="">
              <img v-if="realname_pic1!=''" :data-src="realname_pic1" alt="">
              <p class="audit_image_icon" v-if="realname_pic1==''">
                <svg class="icon wh-32" aria-hidden="true">
                  <use v-if="uploadMsg1" xlink:href="#icon-a-addto"></use>
                  <use v-if="!uploadMsg1" xlink:href="#icon-a-notpass"></use>
                </svg>
              </p>
              <p class="audit_image_memo" v-if="realname_pic1==''&&uploadMsg1">
                <span>点击上传身份证人像面</span>
              </p>
              <p class="audit_image_errormsg" v-if="realname_pic1==''&&!uploadMsg1">
                <span class="r">{{ uploadMsg1text }}</span>
              </p>
            </div>
          </el-upload>
          <el-upload
              accept="image/jpeg,image/png"
              :show-file-list="false"
              :on-success="realnamePic2UploadSuccess"
              :before-upload="beforeRealNameUpload"
              :data="{ side: 'back' }"
              :action="postUrl"
              :headers="headers"
          >

            <div :class="{ 'audit_image': true, 'error': !uploadMsg2 }"
                 v-lazy-container="{ selector: 'img', error: require('@/assets/banner/idcard2.jpg'), loading: require('@/assets/banner/loading.png') }">
              <img v-if="realname_pic2==''" data-src="@/assets/banner/idcard2.jpg" alt="">
              <img v-if="realname_pic2!=''" :data-src="realname_pic2" alt="">
              <p class="audit_image_icon" v-if="realname_pic2==''">
                <svg class="icon wh-32" aria-hidden="true">
                  <use v-if="uploadMsg2" xlink:href="#icon-a-addto"></use>
                  <use v-if="!uploadMsg2" xlink:href="#icon-a-notpass"></use>
                </svg>
              </p>
              <p class="audit_image_memo" v-if="realname_pic2==''&&uploadMsg2">
                <span>点击上传身份证国徽面</span>
              </p>
              <p class="audit_image_errormsg" v-if="realname_pic2==''&&!uploadMsg2">
                <span class="r">{{ uploadMsg2text }}</span>
              </p>
            </div>
          </el-upload>
        </div>

        <p class="exist_title" v-if="isShowList&&showList&&!isAdd">认证信息</p>
        <div
            v-show="isShowList&&showList"
            :class="{'exist_list': true, 'checked': true}"
            v-for="(audit, index) in existRealNameList"
            :key="index">
          <p>{{ audit.personal_real_name }}</p>
          <p>
            <i :class="{
                    'iconfont': true,
                    'icon-personal': true,
                    'wh-12': true,
                    'b': audit.id==currentAuditId}"></i>
            <span>{{ audit.personal_idcard_num }}</span></p>
          <p>
            <i :class="{
                    'iconfont': true,
                    'icon-wait': audit.status==2,
                    'icon-attention': audit.status==3,
                    'icon-success': audit.status==9,
                    'wh-12': true }"
            ></i>
            {{ audit.status_name }}
          </p>
        </div>
        <!--        审核失败-->
        <div class="tip" v-if="selectedRealNameAudit.status==3&&isShowFormAndInfo">
          <p><i class="iconfont icon-attention r wh-18"></i> {{ selectedRealNameAudit.last_verify_memo }}</p>
        </div>
        <!--        描述-->
        <div class="realname_memo" v-if="isAdd&&realname_pic1==''&&realname_pic2==''">
          <p class="realname_intro_title">
            <svg class="icon wh-18" aria-hidden="true">
              <use xlink:href="#icon-question-small"></use>
            </svg>
            &nbsp;&nbsp;对上传的身份证图片有什么要求？
          </p>
          <p class="realname_intro_memo">
            1、证件必须是清晰彩色二代身份证，需要同时提交正反二面；<br>
            2、照片不得涂改，需确保完整、水印、文字、图片、证件号码清晰可辨；<br>
            3、支持jpg、png格式，大小不超过3M
          </p>
        </div>
        <div class="realname_btn_div" v-if="isShowFormAndInfo&&isAdd&&realname_pic1==''&&realname_pic2==''">
          <el-button v-if="selectedRealNameAudit.status!=3&&!isCanIgnore" class="realname_btn" style="margin-top: 20px"
                     size="mini" @click="goToBack">返回
          </el-button>
          <p class="realname_btn_ignore" @click="nextStep" v-else-if="selectedRealNameAudit.status!=3&&isCanIgnore">
            暂不认证，跳过</p>
          <el-button v-else class="realname_btn" size="mini" @click="toCancel">取消</el-button>
        </div>

        <!--        表单-->
        <div>
          <div class="realname_form" v-if="realname_pic1!='' || realname_pic2!=''">
            <el-form ref="realNameForm" :model="realNameForm" :rules="realNameAuthRules" label-width="100px">
              <el-form-item label="真实姓名" prop="personal_real_name">
                <el-input v-model="realNameForm.personal_real_name"  size="small"
                          placeholder="请输入真实姓名"></el-input>
              </el-form-item>
              <el-form-item label="身份证号码" prop="personal_idcard_num">
                <el-input v-model="realNameForm.personal_idcard_num"  size="small"
                          placeholder="请输入身份证号码"></el-input>
              </el-form-item>
              <p v-if="formErrorText!=''" class="r" style="margin-top: 10px; font-size: 14px"><i
                  class="iconfont icon-a-notpass wh-12"></i>{{ formErrorText }}</p>
            </el-form>
          </div>
          <div class="realname_btn_div" v-if="isShowFormAndInfo && (realname_pic1!='' || realname_pic2!='')">
            <el-button type="primary" class="realname_btn" size="mini" @click="toSubmit">提交</el-button>
            <el-button class="realname_btn" size="mini" @click="toCancel">取消</el-button>
          </div>
        </div>
        <!--        列表-->
        <div v-if="isShowFormAndInfo">
          <div class="exitAuditImg" v-if="!isAdd">
            <el-col :span="12"
                    v-lazy-container="{ selector: 'img', error: require('@/assets/banner/idcardfirst.png'), loading: require('@/assets/banner/loading.png') }">
              <img style="width: 50%" v-if="selectedRealNameAudit.personal_idcard_pic1!=''"
                   :data-src="selectedRealNameAudit.personal_idcard_pic1" alt="">
            </el-col>
            <el-col :span="12"
                    v-lazy-container="{ selector: 'img', error: require('@/assets/banner/idcardsecond.png'), loading: require('@/assets/banner/loading.png') }">
              <img style="width: 50%" v-if="selectedRealNameAudit.personal_idcard_pic2!=''"
                   :data-src="selectedRealNameAudit.personal_idcard_pic2" alt="">
            </el-col>
          </div>
          <div class="exitAuditForm" v-if="!isAdd">
            <el-form label-width="100px" :rules="realNameAuthRules">
              <el-form-item label="真实姓名" prop="personal_real_name">
                <span>{{ selectedRealNameAudit.personal_real_name }}</span>
              </el-form-item>
              <el-form-item label="身份证号码" prop="personal_idcard_num">
                <span>{{ selectedRealNameAudit.personal_idcard_num }}</span>
              </el-form-item>
            </el-form>
          </div>
          <div class="realname_btn_div" v-if="!isAdd">
            <el-button class="realname_btn" size="mini" v-if="!isCanIgnore" @click="goToBack">返回</el-button>
            <el-button class="realname_btn" size="mini" v-else @click="nextStep">返回</el-button>
          </div>
        </div>
      </div>

      <el-card shadow="never" class="main-content" v-if="isResult">
        <el-result icon="success" title="审核成功" subTitle="请根据提示进行操作" v-show="false">
          <template slot="extra">
            <el-button type="primary" size="mini" style="width: 160px" @click="lookInfo">查看详情</el-button>
            <el-button style="width: 160px" v-if="!isCanIgnore" size="mini" @click="goToBack(1)">返回</el-button>
          </template>
        </el-result>

        <el-result icon="warning" title="等待审核" subTitle="请根据提示进行操作">
          <template slot="extra">
            <el-button type="primary" style="width: 160px" size="mini" @click="lookInfo">查看详情</el-button>
            <el-button style="width: 160px" v-if="!isCanIgnore" size="mini" @click="goToBack(1)">返回</el-button>
          </template>
        </el-result>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>

import {getUserRealNameAuthList} from "@/api/auth";
import {createpersonAuth} from "@/api/user";
import {checkedTaskAuth} from "@/api/task";
import {getToken} from "@/utils/auth";

export default {
  name: "userAuthIndex",
  components: {},
  props: {
    isCanIgnore: {// 是否可以跳过
      default: false
    },
    isOnlyAdd: { // 是否为新增
      default: false
    },
    isShowList: {// 是否展示选择已有认证列表
      default: true
    },
    isShowFormAndInfo: {// 此操作会隐藏 组件内部的 提交/返回 按钮 以及表单/详情展示
      default: true
    },
    task_id: {// 任务提交主体时 必须
      default: 0
    }
  },
  data() {
    var validatorIdCard = (rule, value, callback) => {
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!reg.test(value)) {
        callback(new Error('填写的身份证号码格式错误'));
      } else {
        callback();
      }
    };
    return {
      isAdd: false,
      isResult: false,
      // 图片上传部分
      postUrl: '/api/auth/upload/idcard',
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      isDisabled: false,// 是否可操作

      // 表单和验证器
      realNameForm: {
        personal_real_name: '',
        personal_idcard_num: ''
      },
      realNameAuthRules: {
        personal_real_name: [
          {required: true, message: '请填写真实姓名', trigger: 'change'}
        ],
        personal_idcard_num: [
          {required: true, message: '请填写身份证号码', trigger: 'change'},
          {validator: validatorIdCard, trigger: 'blur'}
        ],
      },

      existRealNameList: [],
      selectedRealNameAudit: {},
      showList: true,

      uploadMsg1: true,
      uploadMsg1text: '抱歉！上传人像面发生了一些问题请重试!',
      uploadMsg2: true,
      uploadMsg2text: '抱歉！上传国徽面发生了一些问题请重试!',

      currentAuditId: 0,
      realname_pic1: '',
      realname_pic2: '',
      formErrorText: ''
    }
  },
  mounted() {
    this.isAdd = this.isOnlyAdd
    if (!this.isAdd) {
      this.loadRealNameList()
    }
  },
  methods: {
    async loadRealNameList() {
      const res = await getUserRealNameAuthList()
      if (res.statusCode == 200) {
        if (res.data.length > 0) {
          this.existRealNameList = res.data
          // 默认取第一个
          if (this.existRealNameList[0] != undefined) {
            this.selectedRealNameAudit = this.existRealNameList[0]
            if (this.selectedRealNameAudit.status == 3 && this.isShowFormAndInfo) {
              this.toAdd()
            }
            this.$emit('auditBackData', this.selectedRealNameAudit)
          }
        } else {
          this.isAdd = true// 没有过认证恢复到添加
          this.$emit('auditBackData', {id: 0})
        }
      }
    },
    async addRealNameAuth(data) {
      const res = await createpersonAuth(data);
      if (res.statusCode == 200) {
        var taskId = this.task_id;
        if (taskId > 0) {
          this.selectTaskAuth({'sn': taskId, 'auth_id': res.data.id})
        } else {
          this.isResult = true
        }
      } else {
        this.scrollToButtom()
        this.formErrorText = res.message
      }
    },
    async selectTaskAuth(data) {// 完成发布任务后 同步认证主体
      const res = await checkedTaskAuth(data);
      if (res.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '通知',
          message: '认证已提交....'
        });
        this.$redirectUrl.jumpToFrom('/task/result/' + this.task_id);
      } else {
        this.formErrorText = res.message
      }
    },
    realnamePic1UploadSuccess(res, file) {
      if (res.data == "") {
        this.uploadMsg1 = false
        this.uploadMsg1text = res.message
        return;
      } else {
        this.uploadMsg1 = true
      }
      let resultData = res.data[0]
      this.realNameForm.personal_idcard_pic1 = resultData.fileid;
      this.realname_pic1 = URL.createObjectURL(file.raw);
      this.realNameForm.personal_real_name = resultData.ocr.personal_real_name
      this.realNameForm.personal_idcard_num = resultData.ocr.personal_idcard_num
      this.realNameForm.personal_address = resultData.ocr.personal_address
      this.realNameForm.personal_birthday = resultData.ocr.personal_birthday
      this.realNameForm.personal_national = resultData.ocr.personal_national
      if (resultData.ocr.personal_sex_name == '男') {
        this.realNameForm.personal_sex = 1
      } else if (resultData.ocr.personal_sex_name == '女') {
        this.realNameForm.personal_sex = 2
      } else {
        this.realNameForm.personal_sex = 0
      }
    },
    realnamePic2UploadSuccess(res, file) {
      if (res.data == "") {
        this.uploadMsg2 = false
        this.uploadMsg2text = res.message
        return;
      } else {
        this.uploadMsg2 = true
      }
      let resultData = res.data[0]
      this.realNameForm.personal_idcard_pic2 = resultData.fileid;
      this.realname_pic2 = URL.createObjectURL(file.raw);
      this.realNameForm.personal_end_date = resultData.ocr.personal_end_date
      this.realNameForm.personal_issue = resultData.ocr.personal_issue
      this.realNameForm.personal_start_date = resultData.ocr.personal_start_date
    },
    beforeRealNameUpload(file) {
      const isLt3M = file.size / 1024 / 1024 < 3;
      const isJPGORBMPORPNG = ['image/jpeg', 'image/png'].indexOf(file.type) >= 0;
      if (!isJPGORBMPORPNG) {
        this.$message.error('上传证件图必须是 JPG PNG格式!');
      }
      if (!isLt3M) {
        this.$message.error('上传证件图大小不能超过 3MB!');
      }
      return isJPGORBMPORPNG && isLt3M;
    },
    assignValues(backData) {
      // 返回得参数调取这里 可以在这里加一些样式效果
      this.realNameForm = backData
    },
    lookInfo() {
      this.isAdd = false
      this.showList = true
      this.realname_pic1 = ''
      this.realname_pic2 = ''
      this.loadRealNameList()
      this.isResult = false
    },
    goToBack() {
      this.$redirectUrl.jumpToFrom('/home/page')
    },
    toAdd() {
      this.isAdd = true
      this.showList = false
      this.scrollToButtom()
    },
    toCancel() {
      if (this.selectedRealNameAudit.status == 3) {
        this.isAdd = false
        this.showList = true
        this.realname_pic1 = ''
        this.realname_pic2 = ''
      } else {
        this.isAdd = true
        this.realname_pic1 = ''
        this.realname_pic2 = ''
      }
    },
    toSubmit() {
      if (this.realNameForm.personal_idcard_pic1 == undefined) {
        this.uploadMsg1 = false
        return
      }
      if (this.realNameForm.personal_idcard_pic2 == undefined) {
        this.uploadMsg2 = false
        return
      }

      this.$refs.realNameForm.validate(valid => {
        if (valid) {
          let postData = JSON.parse(JSON.stringify(this.realNameForm));
          if (this.selectedRealNameAudit.status == 3) {
            postData.id = this.selectedRealNameAudit.id// 审核失败的是修改
          }
          this.addRealNameAuth(postData);
        } else {
          return false;
        }
      });
    },
    scrollToButtom() {
      let that = this
      this.$nextTick(() => {
        let theBox = this.$refs.mianBox // 获取对
        console.log(theBox.scrollHeight)
        if (theBox) {
          that.$refs.mianBox.scrollIntoView({
            // 平滑滚动
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
          });
        }

      })
    },
    nextStep() {
      this.$emit('auditBackData', {id: 0, toJump: true})
    }
  }
}
</script>

<style scoped>
.el-container {
  margin-bottom: 40px;
}

/deep/ .el-input {
  width: 360px;
}

/deep/ .el-form-item__content {
  text-align: left;
}

/deep/ .el-button--primary {
  background-color: #00a2e6;
}

/deep/ .el-form-item__label {
  font-size: 14px;
  font-weight: bold;
}

/deep/ .el-form-item__error {
  margin-top: 1px !important;
}

.el-main {
  text-align: center;
  overflow: unset;
  padding: unset;
}

/deep/ .el-upload {
  width: 300px;
  height: 200px;
  border: 1px solid #b5b5b5;
}

.main-content {
  max-width: 800px;
  margin: 0 auto;
}

.main-content /deep/ .el-card__body {
  padding: 20px 30px;
}

.realname_image_div {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
}

.audit_image {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.audit_image.error {
  border: 1px solid #E4007F;
}

.audit_image img {
  margin-top: 28px;
  width: 240px;
  height: 145px;
}

.audit_image_icon {
  margin-top: -90px;
}

.audit_image_memo {
  font-size: 12px;
  text-align: center;
  margin-top: 60px;
}

.audit_image_errormsg {
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  margin-top: 20px;
}

.realname_title {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  margin-bottom: 20px;
}

.realname_form {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.realname_btn {
  width: 160px;
}

.realname_btn_ignore {
  font-size: 14px;
  color: #9CA3AF;
  cursor: pointer;
}

.realname_btn_div {
  width: 100%;
  height: 80px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exitAuditImg {
  height: 152px;
}

.exitAuditImg img {

}

.exitAuditForm {
  float: left;
}

.realname_memo {
  width: 100%;
  font-size: 12px;
  text-align: left;
  line-height: 22px;
  float: left;
}

.realname_intro_title {
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.realname_intro_memo {
  font-size: 12px;
  text-align: left;
  line-height: 22px;
}

.tip {
  box-sizing: border-box;
  padding: 20px 16px;
  background-color: #f4f7fd;
  border-radius: 4px;
  border-left: 5px solid #E4007F;
  margin: 20px 0;
  text-align: left;
  font-size: 14px;
}

.exist_title {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

/deep/ .exist_list {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  background-color: #f4f7fd;
  padding: 10px;
  height: 35px;
  margin-bottom: 20px;
}

.exist_list p {
  box-sizing: border-box;
  float: left;
  padding-left: 10px;
}

.exist_list p:nth-child(1) {
  box-sizing: border-box;
  width: 100px;
  border-right: 1px solid #fffdef;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 20px;
  text-overflow: ellipsis;
}

.exist_list p:nth-child(2) {
  box-sizing: border-box;
  width: 460px;
  padding-left: 20px;
  border-right: 1px solid #fffdef;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.exist_list span {
  margin-left: 10px;
}

.exist_list p:nth-child(3) {
  width: 140px;
  padding-left: 40px;
  text-align: left;
}

.exist_list.checked {
  background-color: #00a2e6;
  color: #F8F8F8;
}

.exist_list.checked:hover {
  color: #F8F8F8;
}

.exist_list:hover {
  color: #00a2e6;
}
</style>
