// type 1: timestr 2: dateTime

/**
 * 之前
 * @param theTime
 * @param type
 * @returns {string}
 */
function beforDiffTime (theTime,type) {
    let returnTimeStr = ''
    if (type==2) {
        if(!theTime) {
            return '刚刚'
        }
        theTime = new Date(theTime).getTime()
    }
    let minute = 1000 * 60;
    let hour = minute * 60;
    let day = hour * 24;
    let halfamonth = day * 15;
    let month = day * 30;

    let now = new Date().getTime();
    let diffValue = now - theTime;

    let monthC =diffValue/month;
    let weekC =diffValue/(7*day);
    let dayC =diffValue/day;
    let hourC =diffValue/hour;
    let minC =diffValue/minute;
    if (parseInt(monthC) >=1) {
        returnTimeStr = parseInt(monthC) + '个月前'
    } else if (parseInt(dayC) > 1) {
        returnTimeStr = parseInt(dayC) + '天前'
    } else if (parseInt(dayC) === 1) {
        returnTimeStr = '昨天'
    } else if(parseInt(hourC)>=1){
        returnTimeStr = parseInt(hourC) +"小时前";
    } else if(parseInt(minC)>=1){
        returnTimeStr = parseInt(minC) +"分钟前";
    } else {
        returnTimeStr = "刚刚";
    }
    return returnTimeStr
}

/**
 * 之后
 * @param theTime
 * @param type
 * @returns {string}
 */
function afterDiffTime(theTime,type){
    let returnTimeStr = ''
    if (type==2) {
        if(!theTime) {
            return '刚刚'
        }
        theTime = new Date(theTime).getTime()
    }
    let minute = 1000 * 60;
    let hour = minute * 60;
    let day = hour * 24;
    let halfamonth = day * 15;
    let month = day * 30;

    let now = new Date().getTime();
    let diffValue = theTime - now;

    let monthC =diffValue/month;
    let weekC =diffValue/(7*day);
    let dayC =diffValue/day;
    let hourC =diffValue/hour;
    let minC =diffValue/minute;
    if (parseInt(monthC) >=1) {
        returnTimeStr = parseInt(monthC) + '个月后'
    } else if (parseInt(dayC) > 1) {
        returnTimeStr = parseInt(dayC) + '天后'
    } else if (parseInt(dayC) === 1) {
        returnTimeStr = '明天'
    } else if(parseInt(hourC)>=1){
        returnTimeStr = parseInt(hourC) +"小时后";
    } else if(parseInt(minC)>=1){
        returnTimeStr = parseInt(minC) +"分钟后";
    } else {
        returnTimeStr = null;
    }
    return returnTimeStr
}

function diffTimeDays(theTime) {
    let returnTimeStr = '0天'
    let minute = 1000 * 60;
    let hour = minute * 60;
    let day = hour * 24;

    let now = new Date().getTime();
    let theT = new Date(theTime).getTime()
    let diffValue
    if( typeof (theTime) != 'number' ){
        diffValue = now - theT;
    }else {
        diffValue = now - (theTime*1000);
    }

    let dayC =diffValue/day;
    if (parseInt(dayC) > 1) {
        returnTimeStr = parseInt(dayC) + '天'
    }

    return returnTimeStr
}

export default {
    beforDiffTime,
    afterDiffTime,
    diffTimeDays
}

