<template>
  <div class="taskCheck">
    <div class="banner">
      <!-- 通过动态传入的值 -->
      <p>
				<span v-for="(catitem, catindex) in taskForm.category_pathway" :key="catindex">
					{{ catitem.name }} <i class="iconfont icon-arrow-large-right f-12"
                                v-show="( catindex + 1 ) < taskForm.category_pathway.length"></i>
				</span>
      </p>
    </div>
    <!-- 下面的 是整体的内容 -->
    <div class="info-border" v-loading="showLoading">
      <!-- 左边内容 -->
      <div class="info-border-left">
        <!-- 第一部分网络招工平台解决方案 -->
        <div class="info-border-left-money">
          <div class="info-border-left-money-center">
            <!-- 分为三个内容 -->
            <div class="info-border-left-money-top">
              <div class="info-border-left-money-top1">
                <div class="info-border-left-money-top1-left">
                  <span>项目编号： #{{ taskForm.sn }}</span>
                </div>
                <div class="info-border-left-money-top1-right">
                  <Isvg v-if="taskForm.is_collect > 0" icon-class="collect" @click.native="toUnCollect"/>
                  <Isvg v-if="taskForm.is_collect == 0" icon-class="uncollect" @click.native="toCollect"/>

                  <button @click="taskForm.is_login == 0?toBid('fast'):toBid('normal')" >参与投标</button>
                </div>
              </div>
              <div class="info-border-left-money-top2">
                {{ taskForm.subject }}
              </div>
            </div>
            <!-- 中间内容 -->
            <div class="info-border-left-money-conter">
							<div class="left">
                <span class="spanDis" v-show="diSpan">
								{{ taskForm.content }}
                  <!-- <button @click="disSpan">展开</button> -->
							</span>
                <span class="spanShow" v-show="shSpan">
								{{ taskForm.content }}
								<button @click="showSpan">收起</button>
							</span>
                <div class="info-border-left-money-conter-task">
                  <span v-for="(skillItem, skillIndex) in taskForm.skills" :key="skillIndex">{{ skillItem.name }}</span>
                </div>
                <div class="info-border-left-money-conter-money">
                  <ul>
                    <li>
                      <Isvg icon-class="hire-person" />
                      <span>雇佣{{ taskForm.plan_hire_count }}人</span>
                    </li>
                    <li v-if="taskForm.work_location==1" >
                      <Isvg icon-class="office-online" />
                      <span>在线办公</span>
                    </li>
                    <li v-else >
                      <Isvg icon-class="office-offline" />
                      <span>线下办公</span>
                    </li>

                    <li v-if="taskForm.pay_way == 1">
                      <Isvg icon-class="project-pay" />
                      <span >按项目付费</span>
                    </li>
                    <li v-if="taskForm.pay_way == 2">
                      <Isvg icon-class="hour-pay" />
                      <span >按小时付费</span>
                    </li>
                    <li>
                      <Isvg v-if="taskForm.pay_cycle==1" icon-class="task-week-billing" />
                      <Isvg v-if="taskForm.pay_cycle==2" icon-class="task-month-billing" />
                      <Isvg v-if="taskForm.pay_cycle==3" icon-class="invite-gradient" />
                      <Isvg v-if="taskForm.pay_cycle==4" icon-class="instalments" />
                      <span v-if="taskForm.pay_cycle==1">按周结算</span>
                      <span v-if="taskForm.pay_cycle==2">按月结算</span>
                      <span v-if="taskForm.pay_cycle==3">全额结算</span>
                      <span v-if="taskForm.pay_cycle==4">分期结算</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="right">
                <p>项目预算</p>
                <p class="task-money" v-if="taskForm.min_price!=taskForm.max_price">
                  ￥{{taskForm.min_price}}-{{taskForm.max_price}}
                  <span v-if="taskForm.pay_way==1" >元</span><span v-if="taskForm.pay_way==2" >元/小时</span>
                </p>
                <p class="task-money" v-else>
                  ￥{{taskForm.max_price}}
                  <span v-if="taskForm.pay_way==1" >元</span><span v-if="taskForm.pay_way==2" >元/小时</span>
                </p>
                <div class="task-status" >
                  <p class="status-name" > {{ taskStatusNames[taskForm.status] }} </p>

                  <p v-if="taskForm.status == taskStatus.not_bid">
                    <span> <b>{{ taskForm.bid_start_time }}</b> <br /> 开始投标</span>
                  </p>

                  <p v-if="taskForm.status == taskStatus.bid_working">此任务已在进行中</p>

                  <p v-if="taskForm.status >= taskStatus.bid_in && taskForm.status < taskStatus.bid_working">
                    <span v-if="new Date().getTime() > new Date().getTime()">招标时间已过</span>
                    <span v-else> <b>{{ taskForm.bid_end_time }}</b> <br /> 截止</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- 顶部区域 -->
            <div class="info-border-left-money-bottom" v-show="taskForm.attrs.length > 0">
              <ul>
                <li v-for="(attrItem, attrIndex) in taskForm.attrs" :key="attrIndex">
                  附件{{attrIndex+1}}：
                  <i :class="'iconfont ' + attrItem.icon" class="b wh-12"></i>
                  <el-link :underline="false" class="ml-10" @click="downLoadFile( attrItem.url )">
                    {{ attrItem.file_name }}
                    <i class="el-icon-download"></i>
                  </el-link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!--已投标-->
        <div class="mine-bid" v-if="taskForm.is_login && !taskForm.is_hirer && taskForm.is_bid">
          <div class="title">
            <p>我的投标
              <span v-show="!taskForm.is_finish_base_info">
                <Isvg icon-class="exclamation-mark"/>
                您还可以完善您的个人信息，让雇主更全面认识您。
                <b v-if="perfectStep!=1" @click="perfectStep=1"> 去完善资料 </b>
              </span>
            </p>

          </div>
          <div class="notice" v-if="taskForm.bid_info.freelancer.certifications.real_name<1">
            <Isvg icon-class="exclamation-mark"/>
            系统建议：通过实名认证，会大大增加您中标的概率。我们会对您的实名信息严格保密！
            <b v-if="perfectStep!=2" @click="perfectStep=2"> 去认证 </b>
          </div>
          <div class="notice" v-else>
            <Isvg icon-class="exclamation-mark"/>
            系统提示：
            您已提交实名认证 &nbsp;&nbsp; <b v-if="perfectStep!=2" @click="perfectStep=2"> 查看进度 </b>
          </div>
          <div class="content">
            <TalentsItem
                v-if="perfectStep==0"
                :list="taskForm.bid_info.freelancer"
                mode="bid"
                :bid-data="taskForm.bid_info"
                :is-public="true"
                :can-todo-open-level="true"
            />

            <!--            填写信息表单-->
            <el-form
                v-if="perfectStep==1"
                :model="profileForm"
                :rules="rules"
                ref="profileForm"
                size="small"
                label-width="130px"
                label-position="right">
              <el-form-item prop="avatar" label="头像">
                <el-upload
                    :action="postUrl"
                    accept="image/gif,image/jpeg,image/png"
                    :headers="headers"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :show-file-list="false"
                >
                  <div class="mine-info-photo">
                    <el-avatar v-lazy="avatar" shape="square" :size="100" v-if="avatar" :src="avatar"/>
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item prop="nick_name" label="昵称">
                <el-input
                    style="width: 300px"
                    v-model="profileForm.nick_name"
                    placeholder="限11个字符"
                    maxlength="11"
                    show-word-limit/>
              </el-form-item>
              <el-form-item prop="slogan" label="标语">
                <el-input
                    style="width: 600px"
                    v-model="profileForm.slogan"
                    placeholder="请输入自己的标语，至少包含一个技能。不超过50个字符"
                    maxlength="50"
                    show-word-limit/>
              </el-form-item>

              <el-form-item prop="hourly_rate" label="每小时收费">
                <el-input
                    style="width: 150px"
                    v-model="profileForm.hourly_rate"
                    placeholder="平均收费"
                    type="number"/>
                元/小时
                <span style="margin-left: 10px; font-size: 12px">
                  <Isvg icon-class="exclamation-mark"/>
                  如何评估自己的收费标准？
                </span>
              </el-form-item>

              <el-form-item label="选择分类" prop="skill_ids" v-loading="loading">
                <Skills :title="'行业分类'" :styleAttrs="{ width: '600px' }"
                        @skillCallBack="skillCallBack"/>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="toSubmitProfile" size="small">提交信息</el-button>
                <span
                    style="font-size: 12px; margin-left: 10px;color: #9CA3AF; cursor: pointer"
                    @click="perfectStep=0"
                >暂不完善，跳过</span>
              </el-form-item>
            </el-form>

            <!--            填写认证表单-->
            <RealNameUserAuth
                v-if="perfectStep==2"
                :is-can-ignore="true"
                :is-only-add="taskForm.bid_info.freelancer.certifications.real_name<1?true:false"
                @auditBackData="auditBackData"/>
          </div>
        </div>

        <!--未投标-->
        <div class="mine-bid" v-if="!taskForm.is_bid">
          <div class="title">
            <p>
              {{ taskForm.is_login ? '参与投标' : '快速投标' }}
            </p>
          </div>
          <div class="content" ref="pos" v-if="taskForm.is_can_bid">
            <!--            快速投标表单-->
            <el-form
                v-if="!taskForm.is_login"
                :rules="fastRules"
                :model="fastForm"
                ref="fastForm"
                size="small"
                label-width="130px"
                label-position="right">
              <div style="width: 800px; display: flex; justify-content: left;">
                <el-form-item prop="mobile" label="手机号码">
                  <el-input type="number" v-model="fastForm.mobile" placeholder="请输入您的手机号码"/>
                </el-form-item>

                <el-form-item prop="code" label-width="20px">
                  <el-input type="number" style="width: 120px" v-model="fastForm.code" placeholder="请输入验证码"/>
                </el-form-item>
                <el-form-item label-width="0px">
                  <el-button type="primary" size="small" :style="canClick ? {} : { background: '#91a0a7' }"
                             @click="toSendCode">{{ codemsg }}
                  </el-button>
                </el-form-item>
              </div>

              <el-form-item prop="fixed_price" label="您的竞标价格">
                <el-input :disabled="canEditFixedPrice" type="number" v-model="fastForm.fixed_price"
                          placeholder="请输入您的竞标价格"/>
              </el-form-item>

              <el-form-item prop="is_open_level" label="是否公开竞标价格">
                <el-radio-group v-model="fastForm.is_open_level">
                  <el-radio :label="1">公开</el-radio>
                  <el-radio :label="2">私密（仅自己和雇主可见）</el-radio>
                  <el-radio :label="3">有偿查看（其他竞标者需向您支付2个爪币）</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item prop="memo" label="写给雇主">
                <el-input type="textarea"
                          v-model="fastForm.memo"
                          style="width: 640px"
                          :autosize="{ minRows: 4 }"
                          maxlength="500"
                          show-word-limit
                          placeholder="请简单介绍自己，并针对本项目阐述您的专业和优势，此内容仅对雇主可见。（ 500字以内）"
                />
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="toBid('fast')" size="mini">参与竞标</el-button>
                <span style="font-size: 12px; margin-left: 20px; color: #575757">
                  已注册用户，请直接<a style="color: #00ace6; margin-left: 5px" href="javascript:;" @click="tologin">登录</a>
                </span>
              </el-form-item>
            </el-form>

            <!--            正常投标表单-->
            <el-form
                v-if="taskForm.is_login&&!taskForm.is_bid"
                :rules="bidRules"
                :model="bidForm"
                ref="bidForm"
                size="small"
                label-width="130px"
                label-position="right">

              <el-form-item prop="fixed_price" label="您的竞标价格">
                <el-input :disabled="canEditFixedPrice" type="number" v-model="bidForm.fixed_price"
                          placeholder="请输入您的竞标价格"/>
              </el-form-item>

              <el-form-item prop="is_open_level" label="是否公开竞标价格">
                <el-radio-group v-model="bidForm.is_open_level">
                  <el-radio :label="1">公开</el-radio>
                  <el-radio :label="2">私密（仅自己和雇主可见）</el-radio>
                  <el-radio :label="3">有偿查看（其他竞标者需向您支付2个爪币）</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item prop="memo" label="写给雇主">
                <el-input type="textarea"
                          v-model="bidForm.memo"
                          style="width: 640px"
                          :autosize="{ minRows: 4 }"
                          maxlength="500"
                          show-word-limit
                          placeholder="请简单介绍自己，并针对本项目阐述您的专业和优势，此内容仅对雇主可见。（ 500字以内）"
                />
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="toBid('normal')" size="mini">参与竞标</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="content" ref="pos" v-if="!taskForm.is_can_bid">
            <el-empty description="此任务无法投标！去尝试别的任务吧！"/>
          </div>
        </div>

        <div class="mine-bid">
          <div class="title">
            <p>其他竞标者</p>
          </div>
          <div class="content">
            <el-empty v-show="taskForm.is_open_bids == 'n'" description="项目方不公开竞标者"/>
            <el-empty v-show="taskForm.is_open_bids == 'y' && bidListData.length == 0" description="暂时还没有人投标"/>
            <div v-show="taskForm.is_open_bids == 'y'" v-loading="bidsLoading" class="list"
                 v-for="(item, index) in bidListData" :key="index">
              <TalentsItem
                  v-if="item.freelancer.id!=currentUser.id"
                  :list="item.freelancer"
                  mode="bid"
                  :bid-data="item"
                  :is-public="true"
                  />
            </div>
          </div>
        </div>

      </div>
      <!-- 右边内容 -->
      <div class="info-border-right">
        <div class="info-border-right-add" @click="$router.push('/task/add')">
          <div class="share">
            <span>分享到</span>
            <Isvg icon-class="wechat" />
            <Isvg icon-class="weibo" />
          </div>
          <button @click="$router.push('/task/add')">+ 发布一个类似任务</button>
        </div>

        <el-card class="info-border-right-card" v-if="false">
          <p class="title"> {{ taskStatusNames[taskForm.status] }} </p>

          <p class="desc" v-if="taskForm.status == taskStatus.bid_working">此任务已在进行中</p>
          <p class="desc" v-if="taskForm.status == taskStatus.bid_in">
            <span v-if="new Date().getTime() > new Date(taskForm.bid_end_time).getTime()">招标时间已过</span>
            <b v-else>招标结束于</b>
            <span>{{ diffTime.afterDiffTime(taskForm.bid_end_time, 2) }}</span>
          </p>

          <div class="btnDiv" v-if="taskForm.is_hirer == 0">
            <!-- 不可投标的按钮 -->
            <div v-show="taskForm.is_bid == 0">
              <!--未开标-->
              <el-button
                  v-if="taskForm.status == taskStatus.not_bid"
                  :type="taskForm.is_subcribe == 0? 'primary':'info'"
                  @click="taskForm.is_subcribe == 0?toSubscribe():false"
              >
                {{ taskForm.is_subcribe == 0 ? '订阅这个项目' : '已订阅此项目' }}
              </el-button>
              <p
                  style="font-size: 12px; cursor: pointer"
                  v-if="taskForm.status == taskStatus.not_bid && taskForm.is_subcribe==1"
                  @click="toUnSubscribe">
                取消订阅
              </p>

              <!--投标中-->
              <el-button
                  v-if="taskForm.status == taskStatus.bid_in"
                  type="primary"
                  @click="taskForm.is_login == 0?toBid('fast'):toBid('normal')"
              >
                投标这份工作
              </el-button>
            </div>

            <!-- 可投标的按钮 -->
            <div v-if="taskForm.is_bid == 1">
              <el-button
                  v-if="taskForm.status != taskStatus.bid_working"
                  type="warning"
              >
                已参与竞标
              </el-button>
              <p>您于 {{ taskForm.bid_info.apply_time }} 提交竞标</p>
              <p>若情况有变动，您可选择 <span style="cursor: pointer; color: #00ace6" @click="toCancelBid"> 取消竞标 </span></p>
            </div>
          </div>

          <p class="desc" v-if="taskForm.is_hirer">
            您自己任务，可前往
            <span
                style="cursor: pointer; color: #00ace6"
                @click="$router.push('/home/hirer/task/list')">我发布的任务</span> 操作
          </p>
        </el-card>

        <!-- 关于雇主的信息 -->
        <div class="info-border-right-person">
          <div class="info-border-right-perosn-about"><strong>关于雇主</strong></div>

          <!-- 雇主个人信息 -->
          <div class="info-border-right-person-address">
            <div class="info-border-right-person-address-photo">
              <img v-if="taskForm.hirer.avatar" v-lazy="taskForm.hirer.avatar" alt=""
                   @click="$router.push('/freelancer/info/'+taskForm.hirer.id)"/>
              <div class="info-border-right-person-address-photo-name">
                <p @click="$router.push('/freelancer/info/'+taskForm.hirer.id)" >{{ taskForm.hirer.name }}</p>
                <div class="mb-10"></div>
                <el-link v-if="taskForm.hirer.online == 1" type="primary"
                         style="font-size: 12px;font-weight: bold;margin-right: 5px;">在线
                </el-link>
              </div>
            </div>
            <div class="info-intro"> {{taskForm.hirer.slogan}} </div>
            <div class="info-address">
              <span>入驻时间：{{ taskForm.hirer.join_date }}</span>
              <span>已发布：{{ taskForm.hirer.finish_project_num }} 个项目</span>
            </div>

            <div class="info-border-right-person-start">
              <el-rate v-model="taskForm.hirer.review_score" disabled text-color="#ff9900"
                       score-template="{value}" />
              <span>({{ taskForm.hirer.review_count }}个评价)</span>
            </div>
          </div>
          <!-- 雇主信息认证 -->
          <PersonAtt :authInfo="taskForm.hirer.certifications" :isPublic="true" class="personAtt"
                     :divStyle="{width: '240px', 'margin': '0px auto', 'font-size': '16px' }"/>
        </div>
        <!-- 雇主的其他项目 -->
        <Employer v-show="false"/>
        <SimilarProject v-show="false"/>
      </div>
    </div>
  </div>
</template>

<script>
import Employer from '@/components/Employer';
import SimilarProject from '@/components/SimilarProject';
import {getTaskInfo, applyFast, apply, cancel, collect, uncollect, subscribe, unsubscribe, bidList} from '@/api/task';
import {saveProfile} from '@/api/user';
import {requestLogin, requestSendCode} from '@/api/index';
import Skills from '@/components/Skills';
import PersonAtt from '@/components/PersonAtt'
import {getToken} from "@/utils/auth";
import {TalentsItem} from "@/_components/PersonItem";
import checkedAddressName from "@/utils/checkedAddressName";
import RealNameUserAuth from "@/components/UserAuth/realname";
import diffTime from "@/utils/diffTime";

export default {
  name: 'taskInfo',
  components: {
    Employer,
    SimilarProject,
    Skills,
    PersonAtt,
    TalentsItem,
    RealNameUserAuth
  },
  computed: {
    isLogin() {
      return this.$store.getters["useUserStore/getToken"]
    },
  },
  watch: {
    isLogin: {
      handler() {
        let sn = this.$route.params.sn;
        this.loadTaskInfo({sn: sn});
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    var validatorFixedPrice = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请设置投标价格'));
      }
      if (value < Number(this.taskForm.min_price) || value > Number(this.taskForm.max_price)) {
        callback(new Error('请设置在此项目的规定范围'));
      } else {
        callback();
      }
    };
    var validatorHourlyRate = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('投标金额'));
      }
      if (Number(value) < 10 || Number(value) > 1000) {
        callback(new Error('您的报价请设置在10~1000之间'));
      } else {
        callback();
      }
    };
    var validatorMobile = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请填写手机号码'));
      } else {
        let reg = /^1([0-9])\d{9}$/;
        if (!reg.test(value)) {
          callback(new Error('请填写正确的手机号码'));
        }
      }
      callback();
    };

    return {
      checkedAddressName,
      diffTime,

      currentUser: null,
      showLoading: false,
      postUrl: '/api/upload/avatar',
      headers: {
        Authorization: `Bearer ${getToken()}`
      },

      bidsLoading: false,

      taskStatus: {
        not_bid: 1, // 未开标,
        bid_close: 2, // 已关闭
        bid_in: 3, // 竞标中
        bid_drain: 4, // 已流标
        bid_select: 5, // 选标中
        bid_money: 7, // 资金准备期
        bid_working: 8, // 工作中
        bid_finish: 9 // 已结束
      },
      // 1 => '未开标', 2 => '已关闭', 3 => '竞标中', 4 => '已流标', 5 => '选标中', 7 => '资金准备期', 8 => '工作中', 9 => '已结束'
      taskStatusNames: {
        1: '未开标',
        2: '已关闭',
        3: '竞标中',
        4: '已流标',
        5: '选标中',
        7: '资金准备期',
        8: '工作中',
        9: '已结束'
      },
      diSpan: 1,
      shSpan: 0,
      next: 'true',

      isActive: true,
      hasError: false,
      skillDiv: false,
      taskForm: {
        is_login: 0,
        is_finish_base_info: 0,
        is_bid: 0,
        attrs: [],
        skills: [],
        hirer: {
          certifications: {}
        },
        bid_info: {
          apply_time: '',
          freelancer_info: {}
        },
        min_price: 0,
        max_price: 0
      },

      avatar: require('@/icons/svg/task/上传头像100X100.svg'),
      skills: [],
      bidListData: [],
      profileForm: {
        nick_name: '', //昵称
        avatar_version: 1,
        slogan: '', //标语
        hourly_rate: '', //每小时收费
        intro: '', //多行文本
        region: '', //选择的技能分类
        skills: [], //选择的技能
        skill_ids: [],
        fixed_price: 0,
        checked: false //勾选的协议
      },
      loading: false,
      canEditFixedPrice: false,
      fastForm: {
        fixed_price: '',
        mobile: '',
        code: '',
        apply_memo: '',
        is_open_level: 1
      },
      codemsg: '发送验证码',
      wait: 60,
      canClick: true,
      fastRules: {
        fixed_price: [
          {
            required: true,
            message: '请输入您的竞标价格',
            trigger: 'blur'
          },
          {validator: validatorFixedPrice, trigger: 'blur'}
        ],
        mobile: [
          {
            required: true,
            message: '请输入您的联系电话',
            trigger: 'blur'
          },
          {validator: validatorMobile, trigger: 'blur'}
        ],
        code: [
          {
            required: true,
            message: '验证码不可以为空',
            trigger: 'blur'
          }
        ]
      },
      bidForm: {
        fixed_price: '',
        apply_memo: '',
        is_open_level: 1
      },
      bidRules: {
        fixed_price: [
          {
            required: true,
            message: '请输入您的竞标价格',
            trigger: 'blur'
          },
          {validator: validatorFixedPrice, trigger: 'blur'}
        ]
      },
      rules: {
        avatar: [
          {
            required: true,
            message: '请上传头像',
            trigger: 'change'
          },
        ],
        nick_name: [
          {
            required: true,
            message: '请输入您的昵称',
            trigger: 'blur'
          },
          {
            min: 0,
            max: 11,
            message: '长度在 0 到 11 个字符',
            trigger: 'blur'
          }
        ],
        slogan: [
          {
            required: true,
            message: '请输入您的标语',
            trigger: 'blur'
          }
        ],
        skill_ids: [
          {
            required: true,
            message: '请选择您的技能',
            trigger: 'blur'
          }
        ],
        hourly_rate: [
          {
            required: true,
            message: '请输入您的报价',
            trigger: 'blur'
          },
          {validator: validatorHourlyRate, trigger: 'blur'}
        ]
      },

      clickDis: true,
      showSaveProfileBox: false,

      perfectStep: 0
    };
  },
  created() {
    this.currentUser = this.$store.getters["useUserStore/getUserInfo"]
  },
  mounted() {
    var sn = this.$route.params.sn;
    if (sn == '') {
      this.$notify({
        type: 'error',
        title: '提示',
        message: '该页面不存在'
      });
      return false;
    }
  },
  methods: {
    async loadTaskInfo(data) {
      this.showLoading = true
      const res = await getTaskInfo(data);
      if (res.statusCode == 200) {
        if (res.data.bid_info == null) {
          res.data.bid_info = {};
        }
        this.taskForm = res.data;
        this.taskForm.hirer.review_score = Number(this.taskForm.hirer.review_score);

        if (getToken()) {
          let user = this.currentUser
          this.avatar = user.avatar
          this.profileForm.avatar = user.avatar
          this.profileForm.nick_name = user.name
          this.profileForm.slogan = user.slogan
          this.profileForm.intro = user.intro
          this.profileForm.hourly_rate = user.hourly_rate
        }

        if (this.taskForm.min_price == this.taskForm.max_price) {
          this.fastForm.fixed_price = this.taskForm.max_price > 0 ? this.taskForm.max_price : ''
          this.bidForm.fixed_price = this.taskForm.max_price > 0 ? this.taskForm.max_price : ''
          this.canEditFixedPrice = true
        }

        if( this.taskForm.is_open_bids ){
          this.loadBidList( {task_id: this.taskForm.id, status: 2, page: 1} )
        }
      }

      this.showLoading = false
    },
    async submitProfile(data) {
      const result = await saveProfile(data);
      if (result.statusCode == 200) {
        // 更新本地信息
        this.currentUser.avatar = this.avatar;
        this.currentUser.name = this.profileForm.nick_name;
        this.currentUser.slogan = this.profileForm.slogan;
        this.$store.dispatch('useUserStore/setUserInfo', {user: this.currentUser})

        if (getToken()) {
          this.perfectStep = 2
          this.applyBid({
            task_id: this.taskForm.id,
            hourly_rate: this.profileForm.hourly_rate,
            fixed_price: this.fastForm.fixed_price,
            apply_memo: this.fastForm.apply_memo,
            is_open_level: this.fastForm.is_open_level
          })
        } else {
          this.perfectStep = 2
          this.applyFastBid({
            task_id: this.taskForm.id,
            hourly_rate: this.profileForm.hourly_rate,
            fixed_price: this.fastForm.fixed_price,
            apply_memo: this.fastForm.apply_memo,
            is_open_level: this.fastForm.is_open_level
          })
        }
      } else {
        this.messageLog(result.message);
      }
    },
    async sendCode(data) {
      const result = await requestSendCode(data);
      if (result.statusCode == 200) {
        this.messageLog(result.data, 'success');
        this.codemsg = '60秒';
        this.canClick = false;
        let clock = window.setInterval(() => {
          this.wait--;
          this.codemsg = this.wait + '秒';
          if (this.wait < 0) {
            //当倒计时小于0时清除定时器
            window.clearInterval(clock);
            this.codemsg = '重新发送验证码';
            this.wait = 60;
            this.canClick = true;
          }
        }, 1000);
      } else {
        this.messageLog(result.message);
      }
    },
    async applyFastBid(data) {
      const res = await applyFast(data);
      if (res.statusCode == 200) {

        if( res.data.bid_status == 0 ){
          this.$notify({
            type: 'error',
            title: '提示',
            message: res.data.message
          });
          return;
        }

        this.$notify({
          type: 'success',
          title: '投标通知',
          message: '您已成功投标此项目'
        });

        let user = res.data.login_info;
        this.$store.dispatch('useUserStore/setLoginStatusInfo', user)
        this.taskForm.is_finish_base_info = user.is_finish_base_info

      } else {
        this.$notify({
          type: 'error',
          title: '提示',
          message: res.message
        });
      }
    },
    async applyBid(data) {
      const res = await apply(data);
      if (res.statusCode == 200) {
        if( res.data.bid_status == 0 ){
          this.$notify({
            type: 'error',
            title: '提示',
            message: res.data.message
          });
          return;
        }

        this.$notify({
          type: 'success',
          title: '投标通知',
          message: '您已成功投标此项目'
        });
        window.location.reload();
      } else {
        this.$notify({
          type: 'error',
          title: '提示',
          message: res.message
        });
      }
    },
    async cancelBid(data) {
      const res = await cancel(data);
      if (res.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '投标通知',
          message: '您已成功取消本次投标'
        });
        window.location.reload();
      } else {
        this.$notify({
          type: 'error',
          title: '提示',
          message: res.message
        });
      }
    },
    async toCollect() {
      if (!getToken()) {
        this.tologin()
        return;
      }
      this.taskForm.is_collect = 1;
      if (!this.clickDis) {
        this.$message.error('操作太频繁，请稍后再试');
        return;
      }
      const res = await collect({task_id: this.taskForm.id});
      if (res.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '通知',
          message: '您已成功收藏此项目'
        });
        this.clickDis = false;
        var that = this;
        setTimeout(function () {
          that.clickDis = true;
        }, 3000);
      } else {
        this.taskForm.is_collect = 0;
        this.messageLog(res.message);
      }
    },
    async toUnCollect() {
      if (!getToken()) {
        this.tologin()
        return;
      }
      this.taskForm.is_collect = 0;
      if (!this.clickDis) {
        this.$message.error('操作太频繁，请稍后再试');
        return;
      }
      const res = await uncollect({task_id: this.taskForm.id});
      if (res.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '通知',
          message: '您已取消收藏此项目'
        });
        this.clickDis = false;
        var that = this;
        setTimeout(function () {
          that.clickDis = true;
        }, 3000);
      } else {
        this.taskForm.is_collect = 1;
        this.messageLog(res.message);
      }
    },
    async toSubscribe() {
      this.taskForm.is_subcribe = 1;
      if (!this.clickDis) {
        this.$message.error('操作太频繁，请稍后再试');
        return;
      }
      const res = await subscribe({task_id: this.taskForm.id});
      if (res.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '通知',
          message: '您已订阅此项目'
        });
        this.clickDis = false;
        var that = this;
        setTimeout(function () {
          that.clickDis = true;
        }, 3000);
      } else {
        this.taskForm.is_subcribe = 0;
        this.messageLog(res.message);
      }
    },
    async toUnSubscribe() {
      this.taskForm.is_subcribe = 0;
      if (!this.clickDis) {
        this.$message.error('操作太频繁，请稍后再试');
        return;
      }
      const res = await unsubscribe({task_id: this.taskForm.id});
      if (res.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '通知',
          message: '您已取消订阅此项目'
        });
        this.clickDis = false;
        var that = this;
        setTimeout(function () {
          that.clickDis = true;
        }, 3000);
      } else {
        this.taskForm.is_subcribe = 1;
        this.messageLog(res.message);
      }
    },
    async loadBidList(data) {
      if (this.taskForm.is_open_bids == 'n') {
        return;
      }
      const res = await bidList(data);
      if (res.statusCode == 200) {
        this.bidListData = res.data.list;
      } else {
        //this.messageLog('暂无数据');
      }
    },
    toBid(type) {
      this.$refs.pos.scrollIntoView({
        // 平滑滚动
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      });

      switch (type) {
        case 'normal':
          if (!getToken()) {
            // 弹出登录窗口
            this.$store.dispatch('useUserStore/needLoginModal', true)
            return;
          }
          if (this.taskForm.is_hirer) {
            this.messageLog('不可以竞标自己项目', 'error', '提示');
            return;
          }

          this.$refs.bidForm.validate(valid => {
            if (valid) {
              this.applyBid({
                task_id: this.taskForm.id,
                hourly_rate: this.bidForm.fixed_price,
                fixed_price: this.bidForm.fixed_price,
                apply_memo: this.bidForm.apply_memo,
                is_open_level: this.bidForm.is_open_level
              });
            }
          });
          break;
        case 'fast':
          this.$refs.fastForm.validate(valid => {
            if (valid) {
              this.applyFastBid({
                mobile: this.fastForm.mobile,
                code: this.fastForm.code,
                task_id: this.taskForm.id,
                hourly_rate: 0,
                fixed_price: this.fastForm.fixed_price,
                is_open_level: this.fastForm.is_open_level
              })
            }
          });
          break;
      }
    },
    toCancelBid() {
      this.$confirm('您确定要取消本次投标吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.cancelBid({bid_id: this.taskForm.bid_info.id});
          })
          .catch(() => {
          });
    },
    toSendCode() {
      if (this.fastForm.mobile == '') {
        this.messageLog('请先填写手机号');
        return;
      } else {
        this.sendCode({account: this.fastForm.mobile, code_type: 2});
      }
    },
    toSubmitProfile() {
      console.log(this.profileForm)
      this.$refs.profileForm.validate(valid => {
        if (valid) {
          if (this.profileForm.skill_cat_id == 0 || this.profileForm.skill_ids.length == 0) {
            this.$message.error('请先设置技能分类和技能');
            return;
          }

          if (parseInt(this.profileForm.hourly_rate) <= 0) {
            this.$message.error('您的报价填写不正确');
            return;
          }
          this.submitProfile(this.profileForm);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    disSpan() {
      this.diSpan = 0;
      this.shSpan = 1;
    },
    showSpan() {
      this.diSpan = 1;
      this.shSpan = 0;
    },
    handleAvatarSuccess(res, file) {
      this.avatar = res.data.avatar;
      this.profileForm.avatar_version = res.data.version;
    },
    beforeAvatarUpload(file) {
      const isJPGOrGIF = ['image/jpeg', 'image/gif', 'image/png'].indexOf(file.type) >= 0;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPGOrGIF) {
        this.$notify({
          type: 'error',
          title: '通知',
          message: '上传头像图片只能是 JPG 格式 GIF格式!'
        });
      }
      if (!isLt2M) {
        this.$notify({
          type: 'error',
          title: '通知',
          message: '上传头像图片大小不能超过 2MB!'
        });
      }
      return isJPGOrGIF && isLt2M;
    },
    // notify
    messageLog(message = '', type = 'error', title = '通知') {
      this.$notify({
        type: type,
        title: title,
        message: message
      });
    },
    skillCallBack(data) {
      this.profileForm.skill_ids = data.skill_ids;
      this.profileForm.skills = data.skills
      this.profileForm.skill_cat_id = data.skill_cat_id;
    },
    downLoadFile(url) {
      if (!getToken()) {
        this.tologin()
      } else {
        window.open(url);
      }
    },
    tologin() {
      this.$store.dispatch('useUserStore/needLoginModal', true)
    },
    auditBackData(data){
      // 可以不用不能没有的方法
      if(data.toJump){
        this.perfectStep=0
      }
    }
  }
};
</script>

<style lang="less" scoped>
Header {
  margin-top: 0 !important;
  margin-bottom: 0;
}

.taskCheck {
  background-color: #f8f8f8;
}

.banner {
  width: 100%;
  height: 400px;
  background-image: url(~@/assets/banner/底图.jpg);
  background-size: cover;

  p {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    position: relative;
    top: 40px;
    font-size: 14px;
    color: #fff;
    padding-left: 10px;
  }
}

// info
.info-border {
  width: 1200px;
  margin: 0 auto;
  margin-top: -300px;
  display: flex;
  // 左侧内容
  .info-border-left {
    width: 860px;
    margin-left: 10px;
    // 网络招工平台解决方案
    .info-border-left-money {
      background: #ffffff;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      display: flex;

      .info-border-left-money-center {
        width: 800px;
        margin: 0 auto;
        // 顶部
        .info-border-left-money-top {
          margin-top: 20px;

          .info-border-left-money-top1 {
            display: flex;
            justify-content: space-between;
            height: 22px;

            .info-border-left-money-top1-left {
              line-height: 22px;

              span {
                font-size: 12px;
                color: #B5B5B5;
              }
            }

            .info-border-left-money-top1-right {
              font-size: 20px;

              svg {
                cursor: pointer;
              }

              button {
                cursor: pointer;
                width: 80px;
                padding: 5px 10px;
                font-size: 12px;
                background-color: #FFFFFF;
                background-image: linear-gradient(270deg, #00B4FF 0%, #00B4FF 0%, #1DD0D5 100%, #1DD0D5 100%);
                -webkit-background-clip: text;
                color: transparent;
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                border-radius: 15px;
                margin-left: 20px;
              }
            }
          }

          .info-border-left-money-top2 {
            margin-top: 20px;
            font-size: 16px;
            border-bottom: 1px solid #DCDDDD;
            padding-bottom: 20px;
          }
        }

        // 中间
        .info-border-left-money-conter {
          display: flex;
          justify-content: space-between;
          padding: 20px 0px;

          strong {
            font-size: 14px;
            color: #000;
          }

          .left {
            max-width: 550px;

            .spanDis {
              display: block;
              font-size: 12px;
              color: #575757;
              line-height: 20px;

              button {
                color: #00a2e6;
                background-color: #fff;
              }
            }

            .spanShow {
              display: block;
              font-size: 12px;
              color: #575757;
              line-height: 20px;

              button {
                color: #00a2e6;
                background-color: #fff;
              }
            }

            .info-border-left-money-conter-task {
              margin-top: 20px;
              height: 24px;

              span {
                display: inline-block;
                background-color: #F4F4F4;
                padding: 4px 12px;
                border-radius: 12px;
                color: #575757;
                font-size: 12px;
                margin-right: 10px;
              }
            }

            .info-border-left-money-conter-money {
              ul {
                display: flex;
                justify-content: left;
                align-items: center;
                margin-top: 20px;
                li {
                  font-weight: bold;

                  svg {
                    font-size: 16px;
                    color: #00ace6;
                  }

                  /deep/ span {
                    margin-left: 5px;
                    font-size: 12px;
                    color: #575757;
                  }
                }

                & > li {
                  margin-right: 20px;
                }
              }
            }
          }

          .right {
            margin-left: 20px;
            min-width: 220px;

            p {
              font-size: 12px;
              margin-bottom: 10px;
              color: #575757;
            }

            .task-money {
              font-size: 16px;
              color: #3A3939;
              font-weight: bold;

              span {
                font-size: 12px;
                color: #575757;
              }
            }

            .task-status {
              margin-top: 20px;

              p {
                color: #575757;
                line-height: 16px;
              }

              .status-name {
                color: #00ace6;
                font-weight: 500;
              }
            }
          }
        }

        //底部
        .info-border-left-money-bottom {
          border-top: 1px solid #DCDDDD;
          ul {
            margin: 20px 0;

            li {
              height: 20px;
              display: flex;
              align-items: center;
              color: #575757;
              font-size: 12px;
              font-weight: bold;
              margin-bottom: 15px;

              a {
                color: #575757;
                font-size: 12px;

                :hover {
                  color: #00ace6;
                }
              }
            }
          }
        }
      }
    }

    .mine-bid {
      width: 100%;
      background-color: #ffffff;
      margin-top: 20px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      .title {
        padding: 20px 40px;
        border-bottom: 1px solid #DCDDDD;
        display: flex;
        align-items: center;

        p {
          font-size: 16px;

          span {
            color: #575757;
            font-size: 12px;
            margin-left: 20px;

            svg {
              padding-bottom: 1px;
              width: 12px;
              height: 12px;
            }

            b {
              color: #00ace6;
              cursor: pointer;
            }
          }
        }
      }

      .notice {
        height: 50px;
        width: 100%;
        padding: 10px 40px;
        font-size: 12px;
        color: #575757;
        background-color: #f8f8f8;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }

        b {
          color: #00ace6;
          cursor: pointer;
        }
      }

      .content {
        padding: 10px 40px;

        .el-form {
          padding: 20px 0px;
        }

        .el-input {
          width: 200px;
        }

        .el-button--primary {
          background-color: #00ace6;
          width: 150px;
        }
      }
    }

    // 未登录快速竞标和已经参与竞标可见和不可见
    .info-bodden {
      margin-top: 20px;
      width: 880px;
      min-height: 250px;
      background: #ffffff;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      // 每个tab项
      .info-hodden-center {

        /deep/ .el-tabs__item {
          margin-top: 30px;
          font-size: 18px;
          color: #575757;
        }

        // 第一个tab项的
        /deep/ #tab-first {
          width: 150px;
          text-align: center;
        }

        // 第二个tab项的
        /deep/ #tab-second {
          text-align: center;
          width: 200px;
        }

        // 快速竞标的文字和边框
        .info-hodden-borderfont {
          .info-hodden-borderfont-three {
            ul {
              display: flex;
              margin-top: 30px;
            }

            input {
              line-height: 36px;
              text-indent: 10px;
              font-size: 14px;
              color: #575757;
            }

            main {
              font-size: 14px;
              margin-bottom: 12px;
            }

            p {
              margin-left: 10px;
              margin-top: 4px;
              font-size: 12px;
              color: #e4007f;
            }

            .liFirst {
              margin-right: 10px;

              .input-money {
                width: 160px;
              }

              b {
                position: relative;
                left: -20px;
                font-size: 12px;
              }
            }

            .liSecond {
              input {
                width: 300px;
              }
            }

            .liThree {
              margin-left: 20px;
              margin-top: 27px;

              /deep/ .el-input__inner {
                width: 140px;
              }

              /deep/ .el-input-group__append {
                background-color: transparent;
                color: #fff;
                border: none;
                width: 0;
              }

              button {
                background: #00a2e6;
                font-size: 14px;
              }

              /deep/ .el-input-group__append, .el-input-group__prepend {
                padding: 0;
                vertical-align: middle;
              }
            }
          }

          .info-hodden-button {
            width: 200px;
            margin: 0 auto;
            text-align: center;
            margin-top: 33px;

            button {
              font-size: 18px;
              width: 200px;
              line-height: 36px;
              background: #00a2e6;
              margin-bottom: 20px;
            }

            p {
              font-size: 14px;

              a {
                color: #00a2e6;
              }

              margin-bottom: 40px;
            }
          }
        }

        //已经参与竞标的不空开页面
        .info-hodden-private {
          margin-top: 58px;

          .info-hodden-private-center {
            width: 298px;
            margin: 0 auto;
            text-align: center;
            color: #b5b5b5;
          }

          img {
            width: 298px;
            height: 184px;
          }

          p {
            margin-top: 40px;
            margin-bottom: 60px;
          }
        }

        // 参加竞标的公开页面
        .info-hodden-public-project {
          margin: 0 auto;

          .info-hodden-button {
            width: 200px;
            height: 36px;
            margin: 0 auto;
            margin-top: 40px;
            font-size: 18px;
            cursor: pointer;

            button {
              width: 200px;
              line-height: 36px;
              background: #00a2e6;
            }
          }
        }

        // 我的竞标的信息
        .mine-info {
          p {
            font-size: 12px;
            color: #575757;
          }

          .fixed_price-box {
            width: 400px;
            margin: 0 auto;
          }

          .mine-info-img-font {
            margin-top: 20px;
            display: flex;

            .mine-info-img-border {
              width: 200px;
              height: 200px;

              .mine-info-photo {
                width: 200px;
                height: 200px;
                cursor: pointer;
                margin-bottom: 20px;

                img {
                  width: 240px;
                  height: 240px;
                  object-fit: cover;
                }
              }

              button {
                width: 150px;
                height: 24px;
                background: #00a2e6;
                font-size: 12px;
                color: #fff;
              }
            }

            .mine-info-font-border {
              width: 640px;
              margin-left: 20px;

              .mine-info-font-hour {
                span {
                  font-size: 12px;
                  color: #575757;
                }

                strong {
                  margin-left: 60px;
                  font-size: 12px;
                }

                b {
                  cursor: pointer;
                  color: #00a2e6;
                }
              }

              .form-center-button {
                width: 200px;
                margin: 0 auto;

                button {
                  width: 200px;
                  line-height: 32px;
                  background: #00a2e6;
                  font-size: 14px;
                  color: #fff;
                }
              }

              .skill-Strong {
                display: block;
                line-height: 1;
                margin-top: 10px;
                color: #e4007f;
                font-size: 12px;
              }

              .skill-p {
                line-height: 1;
                margin-top: 10px;
                font-size: 14px;

                span {
                  font-size: 12px;
                  color: #575757;
                  margin-right: 10px;
                }
              }

              .skill-select {
                width: 720px;
              }

              .mine-agreement {
                display: block;
                margin-bottom: 40px;
                font-size: 12px;

                span {
                  color: #00a2e6;
                }
              }

              .mine-button-submit {
                width: 200px;
                height: 36px;
                margin: 0 auto;
                margin-bottom: 60px;

                button {
                  width: 200px;
                  line-height: 36px;
                  background: #00a2e6;
                  font-size: 18px;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  // 右侧内容
  .info-border-right {
    width: 320px;
    margin-left: 20px;

    .info-border-right-add {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      line-height: 60px;
      background: #fff;
      text-align: center;
      color: #00a2e6;

      .share {
        display: flex;
        justify-content: space-between;
        font-size: 32px;
        align-items: center;
        margin: 0px 30px;
        padding-top: 20px;
        border-bottom: 1px solid #DCDDDD;

        span {
          font-size: 18px;
          color: #575757;
        }

        svg {
          cursor: pointer;
        }
      }

      button {
        background: linear-gradient(270deg, #00B4FF 0%, #00B4FF 0%, #1DD0D5 100%, #1DD0D5 100%);
        padding: 5px 30px;
        margin: 20px 0;
        cursor: pointer;
      }
    }

    .info-border-right-card {
      width: 100%;
      min-height: 120px;
      background: #fff;
      margin: 20px 0px;
      text-align: center;

      p {
        font-size: 12px;
        margin-top: 10px;
      }

      .title {
        color: #00a2e6;
        font-size: 24px;
      }

      .desc {
        span {
          color: #d4237a;
          font-size: 12px;
          margin: 0px 5px;
        }
      }

      .btnDiv {
        margin: 20px 0px;

        button {
          width: 200px;
          margin-bottom: 10px;
        }

        .el-button--primary {
          background-color: #00ace6;
        }
      }
    }

    .info-border-right-person {
      background: #ffffff;
      margin-top: 20px;
      padding-bottom: 30px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      .info-border-right-perosn-about {
        line-height: 50px;
        font-size: 16px;
        border-bottom: 1px solid #DCDDDD;

        strong {
          margin-left: 20px;
        }
      }

      .info-border-right-perosn-bid {
        width: 260px;
        height: 160px;
        color: #fff;
      }

      .info-border-right-person-address {
        width: 240px;
        border-bottom: 1px solid #DCDDDD;
        margin: 0 auto;
        padding-bottom: 20px;

        span {
          font-size: 12px;
          color: #575757;
        }

        .info-border-right-person-address-photo {
          margin-top: 20px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            cursor: pointer;
            width: 40px;
            height: 40px;
            margin-right: 10px;
            border-radius: 50%;
          }

          .info-border-right-person-address-photo-name {

            p {
              cursor: pointer;
              display: block;
              width: 180px;
              font-size: 16px;
            }

            span {
              margin-top: 8px;
            }
          }
        }

        .info-intro {
          margin-top: 20px;
          font-size: 12px;
          color: #575757;
          line-height: 24px;
        }

        .info-address {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          font-size: 12px;
          color: #575757;
        }

        .info-border-right-person-start {
          margin-top: 20px;
        }
      }

      .info-border-right-person-attesattion {
        ul {
          height: 200px;
          margin-top: 17px;

          li {
            margin-left: 34px;
            padding-bottom: 12px;
            vertical-align: top;
            display: flex;

            img {
              width: 16px;
              height: 16px;
              margin-right: 24px;
            }

            span {
              font-size: 12px;
              color: #575757;
              position: relative;
              top: 3px;
            }
          }
        }
      }
    }

    // 雇主的其他xinxi
  }
}

// 修改element-ui的数据
/deep/ .el-rate__icon {
  font-size: 12px;
  margin-right: 0px;
}

/deep/ .el-rate {
  display: inline-block;
  margin-right: 10px;
}

/deep/ .el-input__inner {
  border-radius: 0;
}

/deep/ .el-textarea__inner {
  border-radius: 0;
  border: 1px solid #E4E7ED;
  color: unset !important;
}
</style>
